<script setup lang="ts">
import { ClickOutside as vClickOutside } from 'element-plus'
import SlotsBucketPopoverOptions from './SlotsBucketPopoverOptions.vue'
import type { SlotSelectedEvent } from '~/components/appointmentSlots/events'
import type { Bucket } from '~/logic/appointmentBucketing'
import type { Appointment } from '~/models/appointments'

const props = defineProps<{
  bucket: Bucket
  existingAppointment: Appointment | undefined
}>()
const emit = defineEmits({
  book: (event: SlotSelectedEvent) => true,
})

const apptCountStyles = computed(() => {
  if (props.bucket.numSlots === 0) {
    return { backgroundColor: '#f9fafa', color: '#bfc3c7' } // White/gray
  } else if (props.bucket.numSlots > 10) {
    return { backgroundColor: '#739236', color: 'white' } // Green
  } else if (props.bucket.numSlots > 2) {
    return { backgroundColor: '#ebc041', color: '#2a2306' } // Yellow
  } else {
    return { backgroundColor: '#e05241', color: 'white' } // Red
  }
})

// Popover logic
const popoverCreated = ref(false)
const popoverActive = ref(false)
const buttonRef = ref()
const hasAppointment = computed(() => {
  for (const slot of props.bucket.slots) {
    if (slot.booked_appointment) {
      return true
    }
  }
  return false
})
const tooltip = computed(() => {
  if (hasAppointment.value) {
    return `Existing appointment`
  }
  return undefined
})

const onClickOutside = () => {
  popoverActive.value = false
}

function togglePopover() {
  popoverCreated.value = true
  popoverActive.value = !popoverActive.value
}
function padWithZeroes(num: number): string {
  return num.toString().padStart(2, '0')
}
</script>

<template>
  <el-button
    ref="buttonRef"
    v-tooltip="tooltip"
    v-click-outside="onClickOutside"
    class="w-full my-1 slot-bucket-button"
    :class="{
      'no-slots': bucket.numSlots === 0,
      'has-appointment': hasAppointment,
    }"
    type="info"
    :plain="!hasAppointment"
    round
    size="default"
    v-bind="$attrs"
    @click="togglePopover"
  >
    <div class="flex justify-between w-full">
      <div>
        {{ padWithZeroes(props.bucket.hourStart) }} -
        {{ padWithZeroes(props.bucket.hourEnd) }}
      </div>
      <div :style="apptCountStyles" class="slot-count">
        {{ bucket.numSlots }}
      </div>
    </div>
  </el-button>

  <el-popover
    v-if="popoverCreated"
    :virtual-ref="buttonRef"
    virtual-triggering
    placement="bottom"
    :width="360"
    :visible="popoverActive"
    trigger="click"
    @hide="popoverActive = false"
    @show="popoverActive = true"
  >
    <SlotsBucketPopoverOptions
      :bucket="props.bucket"
      :existing-appointment="props.existingAppointment"
      @click.stop=""
      @book="emit('book', $event)"
    />
  </el-popover>
</template>

<style lang="scss">
.slot-bucket-button {
  max-width: 106px;
  margin-left: 0px !important;
  // Just cutting a little width out by shifting the number over, maybe it's best
  // just to leave it aligned 🤷‍♂️
  padding-left: 11px !important;
  display: block;
  span {
    width: 100%;
    display: block;
  }
  $slot-count-size: 24px;
  $half-slot-count-size: calc($slot-count-size / 2);
  .slot-count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $slot-count-size;
    padding: 0 6px;
    height: $slot-count-size;
    border-radius: $half-slot-count-size;
    font-size: $half-slot-count-size;
    margin-top: -5px;
    margin-right: -11px;
    font-weight: bold;
  }
  &.no-slots {
    opacity: 0.3;
    background-color: #f2f2f4;
    color: #9d9fa1;
    border-color: #b8b8b8;
  }
  &:not(.no-slots):not(.has-appointment):not(:hover) {
    background-color: white;
  }

  &.has-appointment {
    @apply shadow-md shadow-blue-gray-700;
  }
}
</style>
