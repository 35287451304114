<script lang="ts" setup>
import type { WithCounts } from './types'
import LoadedContainerIcon from '~/components/icons/LoadedContainerIcon.vue'
import EmptyContainerIcon from '~/components/icons/EmptyContainerIcon.vue'
import DualsIcon from '~/components/icons/DualsIcon.vue'
const props = defineProps<{ group: WithCounts }>()
const tooltip = computed(() => {
  let tooltip = `${props.group.numTransactions} appointments total`
  const subCountsDesc = []
  if (props.group.numSingleImportAppointments) {
    subCountsDesc.push(
      `${props.group.numSingleImportAppointments} imports (not dualed)`
    )
  }
  if (props.group.numDualTransactions) {
    subCountsDesc.push(`${props.group.numDualTransactions} duals`)
  }
  if (props.group.numSingleEmptyAppointments) {
    subCountsDesc.push(
      `${props.group.numSingleEmptyAppointments} single empties`
    )
  }
  if (subCountsDesc.length) {
    tooltip += `: ${subCountsDesc.join(', ')}`
  }
  return tooltip
})
</script>

<template>
  <span v-tooltip="tooltip" class="inline-block mt-3px">
    <span class="count">
      <i-mdi:calendar class="align-text-bottom" />{{
        props.group.numTransactions
      }}
    </span>
    <span v-if="group.numSingleImportAppointments" class="ml-1 count">
      <LoadedContainerIcon class="align-text-bottom" />
      {{ group.numSingleImportAppointments }}
    </span>
    <span v-if="group.numDualTransactions" class="ml-1 count">
      <DualsIcon class="align-text-bottom" />
      {{ group.numDualTransactions }}
    </span>
    <span v-if="group.numSingleEmptyAppointments" class="ml-1 count">
      <EmptyContainerIcon class="align-text-bottom" />
      {{ group.numSingleEmptyAppointments }}
    </span>
  </span>
</template>

<style scoped lang="scss">
.count {
  @apply ml-2;
}
</style>
