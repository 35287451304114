import type { Appointment } from '~/models/appointments'
import type { TerminalName } from '~/services/apiClient'
import { TransactionDirection } from '~/services/apiClient'
import type {
  AppointmentTransaction,
  ShiftGroup,
} from '~/models/groupedAppointments'
import type { BookingRequestState } from '~~/stores/mutationRequests'

export class AppointmentTableRow {
  shiftGroup: ShiftGroup
  transaction: AppointmentTransaction | null
  shiftHeaderIndex: number
  rowKey: string
  constructor(
    shiftGroup: ShiftGroup,
    transaction: AppointmentTransaction | null,
    shiftHeaderIndex: number
  ) {
    this.shiftGroup = shiftGroup
    this.transaction = transaction
    this.shiftHeaderIndex = shiftHeaderIndex
    if (!transaction) {
      this.rowKey = shiftGroup.key
    } else {
      this.rowKey = `${transaction.primary_appointment.appointment.terminal_reference}`
    }
  }

  get reschedulingKey(): string {
    return 'reschedule-' + this.rowKey
  }

  get isHeader() {
    return this.transaction === null
  }

  get terminalLabel() {
    return this.transaction?.terminal_name
  }

  get inboundAppointment() {
    return this.transaction?.inbound_appointment?.appointment
  }

  get outboundAppointment() {
    return this.transaction?.outbound_appointment?.appointment
  }

  get appointmentReferenceDescription() {
    return this.transaction?.primary_appointment.appointment
      .display_terminal_reference
  }

  get moveTypeDescription(): string {
    if (!this.transaction) return ''
    if (
      this.transaction.inbound_appointment &&
      this.transaction.outbound_appointment
    ) {
      return 'Dual'
    }
    return moveDescription(this.transaction.primary_appointment.appointment)
  }

  get statusDescription(): string {
    if (!this.transaction) return ''
    return this.transaction.primary_appointment.appointment.status
  }
}

export interface AppointmentFilters {
  terminals: TerminalName[]
  licensePlate: string | undefined
  moveType: MoveTypeFilter | undefined
  containers: Set<string>
}

function moveDescription(appointment: Appointment) {
  const directText =
    appointment.direction === TransactionDirection.Inbound ? 'In' : 'Out'
  const loadedText = appointment.loaded ? 'Load' : 'Empty'
  return `${loadedText} ${directText}`
}

export interface RescheduleInfo {
  row: AppointmentTableRow
  state: BookingRequestState
  originalAppointment: Appointment
}

export enum MoveTypeFilter {
  HasLoadOut = 'has_load_out',
  HasEmptyIn = 'has_empty_in',
  HasLoadIn = 'has_load_in',
  HasEmptyOut = 'has_empty_out',
  EmptyInOnly = 'empty_in_only',
  LoadOutOnly = 'load_out_only',
  LoadInOnly = 'load_in_only',
  EmptyOutOnly = 'empty_out_only',
  Dual = 'dual',
}
