import type { Appointment } from '~/models/appointments'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type { LinkedAppointment } from '~/services/apiClient'
import { TransactionDirection } from '~/services/apiClient'
import { AppointmentTransaction } from '~/models/groupedAppointments'

// Similar to  groupContainersIntoTransactions

export function groupAppointmentsIntoTransactions(
  appointments: AppointmentWithContainerInfo[]
): AppointmentTransaction[] {
  const groups = groupAppointmentsIntoLinkedGroups(appointments)
  return groups.map(createTransactionFromGroup)
}

function groupAppointmentsIntoLinkedGroups(
  appointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo[][] {
  // Create maps to store appointments and their connections
  const appointmentsByKey = new Map<string, AppointmentWithContainerInfo>()
  const adjacency = new Map<string, Set<string>>()

  // Populate the maps with appointments and their linked appointment --s
  for (const apptInfo of appointments) {
    const apptKey = makeAppointmentKey(apptInfo.appointment)
    appointmentsByKey.set(apptKey, apptInfo)
    adjacency.set(apptKey, new Set())

    const linkedKeys =
      apptInfo.appointment.linked_appointments.map(makeAppointmentKey)
    for (const linkedKey of linkedKeys) {
      // Add bidirectional links between appointments
      adjacency.get(apptKey)!.add(linkedKey)
      if (!adjacency.has(linkedKey)) {
        adjacency.set(linkedKey, new Set())
      }
      adjacency.get(linkedKey)!.add(apptKey)
    }
  }

  // Use depth-first search to group linked appointments
  const visited = new Set<string>()
  const groups: AppointmentWithContainerInfo[][] = []

  for (const key of adjacency.keys()) {
    if (!visited.has(key)) {
      const group: AppointmentWithContainerInfo[] = []
      const stack = [key]

      // Perform depth-first search
      while (stack.length > 0) {
        const currKey = stack.pop()!
        if (!visited.has(currKey)) {
          visited.add(currKey)
          const apptInfo = appointmentsByKey.get(currKey)
          if (apptInfo) {
            group.push(apptInfo)
          }
          // Add unvisited neighbors to the stack
          for (const neighbor of adjacency.get(currKey)!) {
            if (!visited.has(neighbor)) {
              stack.push(neighbor)
            }
          }
        }
      }

      // Add the completed group to the list of groups
      groups.push(group)
    }
  }

  return groups
}

export function createTransactionFromGroup(
  group: AppointmentWithContainerInfo[]
): AppointmentTransaction {
  const transaction = new AppointmentTransaction()
  const [inboundAppointments, outboundAppointments] =
    splitInboundOutbound(group)

  transaction.inbound_appointment =
    selectBestInboundAppointment(inboundAppointments)
  transaction.outbound_appointment =
    selectBestOutboundAppointment(outboundAppointments)

  return transaction
}

function splitInboundOutbound(
  group: AppointmentWithContainerInfo[]
): [AppointmentWithContainerInfo[], AppointmentWithContainerInfo[]] {
  const inbound: AppointmentWithContainerInfo[] = []
  const outbound: AppointmentWithContainerInfo[] = []

  for (const apptInfo of group) {
    if (apptInfo.appointment.direction === TransactionDirection.Inbound) {
      inbound.push(apptInfo)
    } else if (
      apptInfo.appointment.direction === TransactionDirection.Outbound
    ) {
      outbound.push(apptInfo)
    }
  }

  return [inbound, outbound]
}

function selectBestInboundAppointment(
  inboundAppointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo | undefined {
  // TODO: Implement logic to select the best inbound appointment
  // For now, we'll just return the first one if it exists
  return inboundAppointments[0]
}

function selectBestOutboundAppointment(
  outboundAppointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo | undefined {
  // TODO: Implement logic to select the best outbound appointment
  // For now, we'll just return the first one if it exists
  return outboundAppointments[0]
}

function makeAppointmentKey(
  appointment: Appointment | LinkedAppointment
): string {
  return `${appointment.container_number}!!${appointment.terminal_reference}`
}
