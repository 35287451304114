<script setup lang="ts">
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
const props = defineProps<{
  appointment: AppointmentWithContainerInfo | undefined | null
}>()
const shiftRealDateMismatch = computed(() => {
  return (
    props.appointment?.appointment.window_start.toISODate() !==
    props.appointment?.appointment.shiftDate.toISODate()
  )
})
const dateTooltip = computed(() => {
  if (shiftRealDateMismatch.value) {
    const shiftDate = props.appointment?.appointment.shiftDate.toFormat('M/d')
    const realDate = props.appointment?.appointment.window_start.toFormat('M/d')
    return `Shift date is ${shiftDate} but "real" date is ${realDate}`
  }
  return null
})
</script>

<template>
  <div v-if="props.appointment">
    <span class="appt-window">{{
      props.appointment.appointment.window_description
    }}</span>
    <span
      v-if="shiftRealDateMismatch"
      v-tooltip="dateTooltip"
      class="text-gray-400"
    >
      ({{ props.appointment.appointment.window_start.toFormat('M/d') }})
    </span>
  </div>
  <div v-else></div>
</template>

<style scoped lang="scss">
.appt-window {
  @apply inline-block mr-1;
  min-width: 90px;
}
</style>
