<script setup lang="ts">
import { type Column as TableColumn, type TableV2Instance } from 'element-plus'
import type { CellRendererParams } from 'element-plus/lib/components/table-v2/src/types.js'
import Box from './Box.vue'
import ShiftHeaderRow from './ShiftHeaderRow.vue'
import type { RescheduleInfo } from './types'
import { AppointmentTableRow } from './types'
import ActionButtonsCell from './ActionButtonsCell.vue'
import LicensePlateCell from './LicensePlateCell.vue'
import AppointmentNumberCell from './AppointmentNumberCell.vue'
import StatusCell from './StatusCell.vue'
import LFDCell from './LFDCell.vue'
import TimeCell from './TimeCell.vue'
import ContainerNumberCell from './ContainerNumberCell.vue'
import type {
  AppointmentTransaction,
  ShiftGroup,
} from '~/models/groupedAppointments'
import type { Appointment } from '~/models/appointments'
import { type AppointmentStatus } from '~/services/apiClient'
import { useMutationRequestsStore } from '~~/stores/mutationRequests'
import { useAppointmentsRefreshRequestsStore } from '~~/stores/appointmentsRefreshRequests'

const props = defineProps<{
  height: number
  minWidth: number
  appointmentShiftGroups: ShiftGroup[]
  selectedShift: ShiftGroup | undefined
}>()

const emit = defineEmits({
  firstVisibleShiftChanged: (shiftGroup: ShiftGroup) => true,
})

const mutationRequestsStore = useMutationRequestsStore()
const appointmentsRefreshRequestsStore = useAppointmentsRefreshRequestsStore()
const tableRef = ref<TableV2Instance>()
const reschedules = ref(new Map<string, RescheduleInfo>())
const hoveredRowKey = ref<string | null>(null)
const rowHeight = 32
const rowHeightPixels = `${rowHeight}px`

// Map over booking requests updates to our `reschedules` map
watch(mutationRequestsStore.bookingRequestStatesByRequestID, (newVal) => {
  for (const rescheduleInfo of reschedules.value.values()) {
    if (rescheduleInfo.state) {
      const state = newVal.get(rescheduleInfo.state.requestID)
      if (state) {
        rescheduleInfo.state = state
      }
    }
  }
})

const rows = computed((): AppointmentTableRow[] => {
  const rows: AppointmentTableRow[] = []
  let index = 0
  let shiftHeaderIndex = 0
  for (const shiftGroup of props.appointmentShiftGroups) {
    if (shiftGroup.countTotal === 0) continue
    shiftHeaderIndex = index
    rows.push(new AppointmentTableRow(shiftGroup, null, shiftHeaderIndex))
    index += 1
    for (const trx of shiftGroup.transactions) {
      rows.push(new AppointmentTableRow(shiftGroup, trx, shiftHeaderIndex))
      index += 1
    }
  }
  return rows
})

function triggerRefreshAppointments() {
  appointmentsRefreshRequestsStore.triggerRefresh()
}
// set a timer to refresh appointments every 5 minutes
const refreshInterval = setInterval(triggerRefreshAppointments, 20 * 60 * 1000)
onUnmounted(() => {
  clearInterval(refreshInterval)
})

function scrollToShift(shiftGroup: ShiftGroup) {
  // https://element-plus.org/en-US/component/table-v2#manual-scrolling
  const index = rows.value.findIndex(
    (row) => row.shiftGroup.key === shiftGroup.key && row.isHeader
  )
  if (index === -1) {
    throw new Error(`Shift group with key ${shiftGroup.key} not found`)
  }
  tableRef.value?.scrollToRow(index, 'start')
}

defineExpose({
  scrollToShift,
})

const APPT_ATTR_PREFIX = 'appointmentInfo.appointment'
const CONTAINER_COL_WIDTH = 150
const columns: TableColumn[] = [
  {
    minWidth: 156,
    width: 154,
    maxWidth: 180,
    flexGrow: 1,
    title: 'TIME',
    dataKey: `transaction`,
    key: 'window_start',
    cellRenderer: (info: CellRendererParams<AppointmentTransaction | null>) => {
      return h(TimeCell, {
        appointment: info.cellData?.primary_appointment,
      })
    },
  },
  {
    width: 82,
    maxWidth: 100,
    flexGrow: 1,
    title: 'LFD',
    dataKey: 'transaction',
    // TODO: Add some type of "window-LFD" delta info (+1, -1 etc.)
    key: 'lfd',
    cellRenderer: (info: CellRendererParams<AppointmentTransaction>) => {
      return h(LFDCell, {
        appointmentWithInfo: info.cellData.outbound_appointment,
      })
    },
  },
  {
    width: 50,
    maxWidth: 80,
    flexGrow: 1,
    title: 'TERM',
    dataKey: `terminalLabel`,
    key: 'terminal',
  },
  {
    width: CONTAINER_COL_WIDTH + 30,
    title: 'DROP-OFF',
    key: 'inbound_container_number',
    dataKey: `inboundAppointment`,
    cellRenderer: (info: CellRendererParams<Appointment>) => {
      const rowData = info.rowData as AppointmentTableRow
      if (!rowData.transaction) {
        return h('div') // Just a mock value, won't be displayed
      }
      return h(ContainerNumberCell, {
        appointment: info.cellData,
        showDualIcon: !!info.rowData.outboundAppointment,
        transaction: rowData.transaction,
        rowIsHovered: hoveredRowKey.value === rowData.rowKey,
      })
    },
  },
  {
    width: CONTAINER_COL_WIDTH,
    // minWidth: 10,
    // maxWidth: 10,
    title: 'PICK-UP',
    dataKey: `outboundAppointment`,
    key: 'outbound_container_number',
    cellRenderer: (info: CellRendererParams<Appointment>) => {
      const rowData = info.rowData as AppointmentTableRow
      if (!rowData.transaction) {
        return h('div') // Just a mock value, won't be displayed
      }
      return h(ContainerNumberCell, {
        appointment: info.cellData,
        showDualIcon: false,
        transaction: rowData.transaction,
        rowIsHovered: hoveredRowKey.value === rowData.rowKey,
      })
    },
  },
  {
    width: 90, // "Empty Out" is the longest type
    title: 'TYPE',
    key: 'appt_type',
    dataKey: `moveTypeDescription`,
  },
  {
    width: 94,
    title: 'STATUS',
    key: 'status',
    dataKey: `statusDescription`,
    cellRenderer: (info: CellRendererParams<AppointmentStatus>) => {
      return h(StatusCell, {
        status: info.cellData,
      })
    },
  },
  {
    width: 100,
    maxWidth: 160,
    flexGrow: 1,
    title: 'APPT #',
    key: 'appt_ref',
    dataKey: `appointmentReferenceDescription`,
    cellRenderer: (info: CellRendererParams<string>) => {
      return h(AppointmentNumberCell, {
        appointmentReference: info.cellData,
      })
    },
  },
  //   We only ever show scheduled appointments currently, so unless we change this what's
  // the point?
  //   {
  //     width: 100,
  //     title: 'STATUS',
  //     key: 'status',
  //     dataKey: `${APPT_ATTR_PREFIX}.status`,
  //   },
  {
    minWidth: 140,
    width: 140,
    maxWidth: 300,
    flexGrow: 2,
    title: 'LICENSE PLATE',
    dataKey: `${APPT_ATTR_PREFIX}.truck_license_plate_number`,
    key: 'license_plate',
    cellRenderer: (info: CellRendererParams<any>) => {
      const rowData = info.rowData as AppointmentTableRow
      if (!rowData.transaction) {
        return h('div') // Just a mock value, won't be displayed
      }
      return h(LicensePlateCell, {
        transaction: rowData.transaction,
      })
    },
  },
  //   Action buttons
  {
    width: 200,
    title: '',
    key: 'actions',
    cellRenderer: (info: CellRendererParams<any>) => {
      const rowData = info.rowData as AppointmentTableRow
      if (!rowData.transaction) {
        return h('div') // Just a mock value, won't be displayed
      }
      const reschedulingInfo = getReschedulingInfoForRow(rowData)

      return h(ActionButtonsCell, {
        transaction: rowData.transaction,
        rescheduling: !!reschedulingInfo,
        reschedulingStatus: reschedulingInfo?.state.status,
        rowData,
        onRescheduleBegun: (rescheduleInfo: RescheduleInfo) => {
          reschedules.value.set(rowData.reschedulingKey, rescheduleInfo)
        },
      })
    },
  },
]

function getReschedulingInfoForRow(
  row: AppointmentTableRow
): RescheduleInfo | undefined {
  if (!row.transaction) return
  return reschedules.value.get(row.reschedulingKey)
}

const minColsWidth = columns.reduce((acc, col) => acc + col.width, 0) + 10
const tableWidth = computed(() => {
  return Math.max(minColsWidth, props.minWidth)
})

// This is inspired from the following example:
// https://element-plus.org/en-US/component/table-v2#colspan
const RowRenderer = ({
  cells,
  rowData,
}: {
  cells: VNode[]
  rowData: AppointmentTableRow
}) => {
  if (rowData.isHeader) {
    // Have to use a cell that does not have a cellRenderer as our template
    const firstCell = cells[2]
    const style = {
      ...firstCell.props!.style,
      // Make the one cell consume the width of all original cells
      width: `${tableWidth.value}px`,
      height: '20px',
      // Using this width keeps this displayed even when table is wider than screen
      'max-width': `${props.minWidth}px`,
    }

    // Put our "ShiftHeaderRow" inside this cell and set the width to cover the
    // whole row
    const newCell = h(
      firstCell.type as string,
      { ...firstCell.props, style },
      () => [h(ShiftHeaderRow, { shiftGroup: rowData.shiftGroup })]
    )
    return [newCell]
  } else {
    return cells
  }
}

const rowClass = ({ rowData }: { rowData: AppointmentTableRow }) => {
  const classes = []
  if (rowData.isHeader) {
    classes.push('shift-header')
  }
  if (rowData.shiftGroup.key === props.selectedShift?.key) {
    classes.push('is-selected')
  }
  const reschedulingInfo = getReschedulingInfoForRow(rowData)
  if (reschedulingInfo?.state) {
    if (
      reschedulingInfo.state.status === 'pending' ||
      reschedulingInfo.state.status === 'booked'
    ) {
      classes.push('rescheduling')
    } else if (reschedulingInfo.state.status === 'failed') {
      classes.push('rescheduling-failed')
    } else if (reschedulingInfo.state.status === 'rejected') {
      classes.push('rescheduling-rejected')
    }
  }
  return classes.join(' ')
}
const stickyRowIndex = ref(0)
const onScroll = ({ scrollTop }: { scrollTop: number }) => {
  const firstVisibleRowIndex = Math.floor(scrollTop / rowHeight)
  if (firstVisibleRowIndex >= rows.value.length) return
  const firstVisibleRow = rows.value[firstVisibleRowIndex]
  if (stickyRowIndex.value !== firstVisibleRow.shiftHeaderIndex) {
    stickyRowIndex.value = firstVisibleRow.shiftHeaderIndex
    emit('firstVisibleShiftChanged', firstVisibleRow.shiftGroup)
  }
}
const fixedRows = computed(() => {
  return rows.value.slice(stickyRowIndex.value, stickyRowIndex.value + 1)
})

const extraRowProps = ({ rowData }: { rowData: AppointmentTableRow }) => {
  return {
    onMouseenter: () => {
      hoveredRowKey.value = rowData.rowKey
    },
    onMouseleave: () => {
      hoveredRowKey.value = null
    },
  }
}
</script>

<template>
  <Box class="overflow-hidden">
    <el-table-v2
      ref="tableRef"
      :row-class="rowClass"
      row-key="rowKey"
      :data="rows.slice(1)"
      :fixed-data="fixedRows"
      :row-height="rowHeight"
      :header-height="rowHeight"
      :columns="columns"
      :width="tableWidth"
      :height="height"
      :on-scroll="onScroll"
      :scrollbar-always-on="true"
      :cache="10"
      :row-props="extraRowProps"
      class="appointments-table"
    >
      <template #row="rowProps">
        <RowRenderer v-bind="rowProps" />
      </template>
    </el-table-v2>
  </Box>
</template>

<style lang="scss">
.appointments-table {
  --row-height: v-bind(rowHeightPixels);
  .shift-header {
    @apply bg-gray-200;
    border-bottom: 1px solid rgb(193, 193, 193) !important;
    border-top: 1px solid rgb(193, 193, 193) !important;
    // If we wanted to follow Mart's design faithfully
    // border-bottom: 0px !important;
    &.is-fixed {
      @apply bg-blue-gray-300 !important;
      @apply border-blue-gray-400 !important;
    }
    &.is-selected {
      // TODO: Use common vars with ShiftOption.vue
      @apply bg-blue-100 rounded-4px;
      @apply border-blue-700 bg-blue-100 !important;
      border-width: 2px;
    }
  }
  .el-table-v2__row-depth-0,
  .el-table-v2__row-cell {
    height: var(--row-height) !important;
  }
  .rescheduling {
    @apply bg-yellow-100;
  }
  .rescheduling-failed {
    @apply bg-red-100;
  }
  .rescheduling-rejected {
    @apply bg-orange-100;
  }
}
</style>
