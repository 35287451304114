<script lang="ts" setup>
import { ElAlert } from 'element-plus'
import CredentialsInput from '../inputs/CredentialsInput.vue'
import Help from '~/components/display/Help.vue'
import {
  EDITABLE_CONNECTION_TYPES,
  UAS_EDITABLE_CONNECTION_TYPES,
} from '~/constants'
import { useConnectionsStore } from '~/stores/connections'
const props = defineProps<{
  uasConnectionsOnly: boolean
}>()

const connectionsStore = useConnectionsStore()
onMounted(() => {
  connectionsStore.load()
})

const connectionTypes = computed(() => {
  if (props.uasConnectionsOnly) {
    return UAS_EDITABLE_CONNECTION_TYPES
  }
  return EDITABLE_CONNECTION_TYPES
})

const alertDesc = computed(() => {
  if (props.uasConnectionsOnly) {
    return (
      'To use the UAS, you must have valid login credentials for the following ' +
      'accounts.'
    )
  }
  return (
    'To use Dray Dog, you must have valid login credentials for the following ' +
    'accounts.'
  )
})
</script>

<template>
  <div class="mb-4 max-w-800px">
    <ElAlert title="Directions" type="info" show-icon :closable="false">
      {{ alertDesc }}
    </ElAlert>
  </div>
  <div v-loading="connectionsStore.loading">
    <div
      v-for="connectionMeta in connectionTypes"
      :key="connectionMeta.type"
      class="flex"
    >
      <a
        :href="connectionMeta.url"
        target="blank"
        rel="noreferrer"
        class="portal-link"
      >
        <span class="underline">{{ connectionMeta.label }}</span>
        <i-mdi:open-in-new class="align-text-bottom ml-1" />
      </a>

      <CredentialsInput :connection-meta="connectionMeta" />
      <div v-if="connectionMeta.note">
        <Help
          v-if="connectionMeta.note"
          :title="connectionMeta.label"
          class="ml-1 mt-6px"
          >{{ connectionMeta.note }}</Help
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.portal-link {
  @apply inline-block w-210px text-right pr-5px mt-3 mr-2;
}
</style>
