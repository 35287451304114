import type { DateTime } from 'luxon'
import { shiftFromTime } from './shifts'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import type { Shift } from '~/services/apiClient'
const BUCKET_HOURS = 3
const BUCKET_STARTS: number[] = []
for (let hour = 0; hour < 24; hour++) {
  if (hour % BUCKET_HOURS === 0) BUCKET_STARTS.push(hour)
}
export const BUCKET_INFOS: { description: string }[] = []

for (const bucketStart of BUCKET_STARTS) {
  const bucketEnd = bucketStart + BUCKET_HOURS

  BUCKET_INFOS.push({
    description: `${bucketStart}-${bucketEnd}`,
  })
}

export class Bucket {
  hourStart: number
  hourEnd: number
  slots: BaseAppointmentSlot[]
  bucketStart: DateTime
  bucketEnd: DateTime
  shift: Shift
  shiftDate: DateTime
  hasBookedAppointment: boolean

  constructor(bucketStart: DateTime, bucketEnd: DateTime) {
    this.bucketStart = bucketStart
    this.hourStart = bucketStart.hour
    this.hourEnd = bucketEnd.hour
    this.slots = []
    this.bucketEnd = bucketEnd
    this.hasBookedAppointment = false
    const [shiftDate, shift] = shiftFromTime(bucketStart)
    this.shiftDate = shiftDate
    this.shift = shift
  }

  get numSlots(): number {
    return this.slots.reduce(
      (prev, slot) =>
        prev +
        (slot.num_appointments_available === undefined ||
        slot.num_appointments_available === null
          ? 1
          : slot.num_appointments_available),
      0
    )
  }

  get numSlotsIsLowerBound(): boolean {
    return this.slots.some(
      (slot) =>
        slot.num_appointments_available === undefined ||
        slot.num_appointments_available === null
    )
  }

  get hasAvailability(): boolean {
    return this.numSlots > 0
  }

  addSlot(slot: BaseAppointmentSlot) {
    this.slots.push(slot)
    if (slot.booked_appointment) {
      this.hasBookedAppointment = true
    }
  }
}

export function bucketAppointments(
  slots: BaseAppointmentSlot[],
  date: DateTime
): Bucket[] {
  const buckets = new Map<Number, Bucket>()
  for (const startHour of BUCKET_STARTS) {
    const bucketStart = date.set({ hour: startHour })
    const bucketEnd = bucketStart.plus({ hours: BUCKET_HOURS })
    buckets.set(startHour, new Bucket(bucketStart, bucketEnd))
  }
  for (const slot of slots) {
    const hour = slot.window_start.hour
    const hourBucket = Math.floor(hour / BUCKET_HOURS) * BUCKET_HOURS
    buckets.get(hourBucket)!.addSlot(slot)
  }
  for (const bucket of buckets.values()) {
    bucket.slots.sort((a, b) => a.window_start.diff(b.window_start).valueOf())
  }
  return [...buckets.values()]
}

export function bucketAppointmentsForRange(
  slots: BaseAppointmentSlot[],
  start: DateTime,
  end: DateTime,
  bucketHoursStarts: number[]
): Bucket[] {
  /**
   * Buckets appointments into time slots.
   *
   * Buckets will be created for every bucket hour from start to end,
   * even if there are no slots in that bucket.
   *
   * @param slots - The appointment slots to bucket
   * @param start - The start time for bucketing
   * @param end - The end time for bucketing
   * @param bucketHoursStarts - The starting "hour of day" for each bucket
   * @returns An array of Bucket objects
   */
  // Generate ranges from bucket hour starts
  bucketHoursStarts.sort((a, b) => a - b)
  if (bucketHoursStarts[0] !== 0) {
    throw new Error('Bucket hours must start at 0')
  }
  const hourRanges: { start: number; end: number }[] = []
  for (let i = 0; i < bucketHoursStarts.length; i++) {
    const startHour = bucketHoursStarts[i]
    if (startHour < 0 || startHour >= 24) {
      throw new Error(`Invalid start hour: ${startHour}`)
    }
    let endHour: number
    if (i === bucketHoursStarts.length - 1) {
      endHour = 24
    } else {
      endHour = bucketHoursStarts[i + 1]
    }
    hourRanges.push({
      start: startHour,
      end: endHour,
    })
  }
  // Create buckets starting and ending on the bucket hours, from start to end
  let currentTimeCursor = start
  let hourRangeIndex = 0
  const buckets: Bucket[] = []
  while (currentTimeCursor < end) {
    // Rotate through hour range index until it matches our current time
    while (
      currentTimeCursor.hour >= hourRanges[hourRangeIndex].end ||
      currentTimeCursor.hour < hourRanges[hourRangeIndex].start
    ) {
      hourRangeIndex = (hourRangeIndex + 1) % hourRanges.length
    }
    const { start: bucketStartHour, end: bucketEndHour } =
      hourRanges[hourRangeIndex]
    const bucketStart = currentTimeCursor.set({ hour: bucketStartHour })
    const bucketEnd = bucketStart.plus({
      hours: bucketEndHour - bucketStartHour,
    })
    buckets.push(new Bucket(bucketStart, bucketEnd))
    currentTimeCursor = bucketEnd
  }
  // Bucket the slots
  slots.sort((a, b) => a.window_start.diff(b.window_start).valueOf())
  let bucketIx = 0
  for (const slot of slots) {
    // Advance to next bucket if needed
    while (
      bucketIx < buckets.length &&
      slot.window_start >= buckets[bucketIx].bucketEnd
    ) {
      bucketIx += 1
    }
    if (bucketIx >= buckets.length) {
      break // This slot and all following slots are after our range
    }

    buckets[bucketIx].addSlot(slot)
  }
  return buckets
}
