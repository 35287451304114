<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { ComputedRef } from 'vue'
import { formatDate } from '~/utils'
import ColoredBadge from '~/components/display/ColoredBadge.vue'

const props = defineProps<{
  lastFreeDate: DateTime | null
  info?: string
}>()

function countLastFreeDays(lfd: DateTime): number {
  return Math.ceil(lfd.diff(DateTime.now()).as('days'))
}
const numFreeDays: ComputedRef<number | undefined> = computed(() => {
  if (props.lastFreeDate) return countLastFreeDays(props.lastFreeDate)
})
const colorBadgeType = computed(() => {
  if (numFreeDays.value !== undefined) {
    if (numFreeDays.value > 1) return 'success'
    else if (numFreeDays.value >= 0) return 'warning'
    else return 'danger'
  } else {
    return 'info'
  }
})
</script>

<template>
  <template v-if="lastFreeDate">
    {{ formatDate(lastFreeDate) }}
    <span v-if="info" v-tooltip="info">ℹ️</span>
  </template>
  <ColoredBadge class="ml-2" :type="colorBadgeType">
    <template v-if="lastFreeDate"> {{ numFreeDays }} days </template>
    <template v-else>???</template>
  </ColoredBadge>
</template>
