<script lang="ts" setup>
import { ConnectionValidationStatus } from '~/services/apiClient'
import { useConnectionsStore } from '~/stores/connections'
import { useUserStore } from '~/stores/user'
const connectionsStore = useConnectionsStore()
const userStore = useUserStore()

onMounted(() => {
  if (!userStore.isTerminalOrg) {
    connectionsStore.loadConnectionsIfNotLoaded()
  }
})
const connections = toRef(connectionsStore, 'connections')
const invalidConnections = computed(() => {
  return connections.value
    .filter((c) => c.validation_status === ConnectionValidationStatus.Invalid)
    .map((c) => c.type.toUpperCase().replace('_', ' '))
    .join(', ')
})
</script>

<template>
  <div
    v-if="invalidConnections"
    class="text-sm text-gray-700 py-2 px-4 rounded"
    style="background-color: rgb(246, 203, 99)"
  >
    <slot style="text-align: center">
      <el-icon><i-ic:outline-notifications-active /></el-icon> Invalid Terminal
      Credentials for: <strong>{{ invalidConnections }}. </strong>
      <router-link
        :to="{
          name: 'settings',
          query: { tab: 'credentials' },
        }"
        ><u>Update Now</u>
        <el-icon><i-material-symbols:arrow-outward /></el-icon></router-link
    ></slot>
  </div>
</template>
