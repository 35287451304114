<script setup lang="ts">
import type { PendingOrganization, UserRoleType } from '~/services/apiClient'
import { OrganizationSubscription, UsersApi } from '~/services/apiClient'

useHead({
  title: 'Dray Dog - Signup',
})

const sign_up_complete = ref(false)
const pendingOrgs = ref([] as PendingOrganization[])
const route = useRoute()
const router = useRouter()
const loading_user_info = ref(false)
const userRole = ref(route.query.role as UserRoleType | undefined)
const orgSubscription = ref(
  route.query.subscription as OrganizationSubscription | undefined
)
const token = route.query.token as string
onMounted(() => {
  const api = new UsersApi()
  loading_user_info.value = true
  api
    .getUserSetupInfoUsersSetupInfoPost({
      token,
    })
    .then((resp) => {
      const respData = resp.data
      pendingOrgs.value = respData.pending_organizations
      sign_up_complete.value = respData.is_user_set_up
    })
    .finally(() => {
      loading_user_info.value = false
    })
})

const needToAcceptAdditionalRoles = computed(() => {
  return (
    pendingOrgs.value.length > 1 ||
    (sign_up_complete.value && pendingOrgs.value.length > 0)
  )
})
watch([sign_up_complete, needToAcceptAdditionalRoles], () => {
  if (sign_up_complete.value && !needToAcceptAdditionalRoles.value)
    if (orgSubscription.value === OrganizationSubscription.Terminal) {
      router.push({ name: 'empty-returns' })
    } else {
      router.push({ name: 'containers' })
    }
})
const title = computed(() => {
  if (!sign_up_complete.value) {
    return 'Sign Up'
  } else if (needToAcceptAdditionalRoles.value) {
    return 'Accept Organization Invites'
  } else {
    return 'Invite already accepted'
  }
})

function handleSignupComplete(role: UserRoleType) {
  sign_up_complete.value = true
  userRole.value = role
}
</script>

<template>
  <OnboardingCard>
    <template #title> {{ title }} </template>
    <div v-if="loading_user_info">Loading...</div>
    <SignUpForm
      v-else-if="!sign_up_complete"
      @signup-complete="handleSignupComplete"
    />
    <AcceptOrgRoleForm
      v-else-if="needToAcceptAdditionalRoles"
      :token="token"
      :pending-roles="pendingOrgs"
      :user-role="userRole"
    />
    <div v-else class="text-center my-8">
      <router-link
        v-if="orgSubscription === OrganizationSubscription.Terminal"
        :to="{ name: 'empty-returns' }"
      >
        <el-button type="primary" size="large">
          View Empty Return Rules
        </el-button>
      </router-link>
      <router-link v-else :to="{ name: 'containers' }">
        <el-button type="primary" size="large"> View Containers </el-button>
      </router-link>
    </div>
  </OnboardingCard>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: sign_up
</route>
