import { ElMessageBox } from 'element-plus'
import type { Duration } from 'luxon'
import { DateTime } from 'luxon'
import type { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import type { ContainerType, TransactionDirection } from './services/apiClient'
import { AppointmentStatus } from './services/apiClient'

// To make mocking easier
export function reloadPage() {
  window.location.reload()
}
export function formatDate(date: DateTime): string {
  return date.toFormat('M/d')
}
export function formatDT(date: DateTime): string {
  return date.toFormat('ccc M/d H:mm')
}
export function formatDTShort(date: DateTime): string {
  return date.toFormat('M/d H:mm')
}
export function formatDTMilitary(date: DateTime): string {
  return date.toFormat('M/d HH:mm')
}
export function formatDTMilitaryWDate(date: DateTime): string {
  return date.toFormat('ccc M/d HH:mm')
}
export function formatDTMilitaryWDateAndYear(date: DateTime): string {
  //  Example: Mar 5, 2024, 14:45
  return date.toFormat('LLL d, yyyy H:mm')
}

export function truncate(s: string, maxLen: number): string {
  const maxEffectiveLen = maxLen - 3
  if (s.length > maxEffectiveLen) {
    return s.substring(0, maxEffectiveLen) + '...'
  } else {
    return s
  }
}

export function appointmentStatusColor(status: AppointmentStatus): string {
  switch (status) {
    case AppointmentStatus.Scheduled:
      return '#005ac7'
    case AppointmentStatus.Completed:
      return '#02bd2a'
    case AppointmentStatus.Missed:
      return '#ed1f26'
    case AppointmentStatus.Cancelled:
      return '#787878'
    case AppointmentStatus.Aborted:
      return '#e89700'
    case AppointmentStatus.InProgress:
      return '#edb600'
    default:
      return '#ff0000'
  }
}

export function cleanRoute(obj: object) {
  const newObj = {}
  for (const [k, v] of Object.entries(obj)) {
    if (v !== undefined && v !== null && v !== '') {
      // @ts-expect-error
      newObj[k] = v
    }
  }
  return newObj
}

export function updateParam(
  router: Router,
  route: RouteLocationNormalizedLoaded,
  key: string,
  value: string | number | boolean | undefined
) {
  const newRoute = {
    ...route.query,
  }
  newRoute[key] = value === undefined ? '' : String(value)
  router.push({
    query: cleanRoute(newRoute),
  })
}

export function moveDescription(
  direction: TransactionDirection,
  loaded: boolean
): string {
  const loadedDesc = loaded ? 'Load' : 'Empty'
  const directionDesc = direction === 'inbound' ? 'In' : 'Out'
  return `${loadedDesc} ${directionDesc}`
}
let diffToSystemTime: Duration | undefined
function calculateDiffToSystemTime() {
  const now = DateTime.now()
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  diffToSystemTime = now.diff(
    now.setZone(systemTimeZone, { keepLocalTime: true })
  )
  // const luxonTimeZone = now.zoneName
  // console.log(
  //   `${diffToSystemTime.toHuman()} from system timezone ${systemTimeZone} to ${luxonTimeZone}`
  // )
  return diffToSystemTime
}

export function getDiffToSystemTime(): Duration {
  if (!diffToSystemTime) {
    diffToSystemTime = calculateDiffToSystemTime()
  }
  return diffToSystemTime
}

export function toJSDate(dt: DateTime) {
  return dt.setZone('system').minus(getDiffToSystemTime()).toJSDate()
}

export const CONTAINER_RE = /^([a-zA-Z]{3})([UJZujz])(\d{6})(\d)$/
const CONTAINER_SEARCH_RE =
  /(^|(?<=[\s,\|]))([a-zA-Z]{3})([UJZujz])(\d{6})-?(\d)((?=[\s,\|])|$)/g

export function parseContainerNumbers(text: string): string[] {
  const containerNumbers = [...text.matchAll(CONTAINER_SEARCH_RE)].map((m) =>
    m[0].replace(/-/g, '')
  )
  return [...new Set(containerNumbers)]
}

export function shortenContainerType(
  type: ContainerType | undefined
): string | undefined {
  if (!type) {
    return type
  }
  return type.replace('Reefer', 'RF').replace(' ', '').replace('HC RF', 'RF')
}

export function confirmBox({
  title,
  message,
  confirmButtonText,
  cancelButtonText = 'Cancel',
  confirmButtonClass,
}: {
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText?: string
  confirmButtonClass?: string
}): Promise<any> {
  return ElMessageBox.confirm(message, title, {
    confirmButtonText,
    confirmButtonClass,
    cancelButtonText,
  })
}

export function standardizeLicensePlate(
  licensePlateNumber: string
): string | null {
  // Replace non-alphanumeric characters (except hyphen) with empty string
  // and convert to uppercase
  const cleanedPlate = licensePlateNumber
    .toUpperCase()
    .replace(/[^A-Z0-9-]/g, '')
    .trim()

  // Return null if the cleaned plate is empty, otherwise return the cleaned plate
  return cleanedPlate || null
}

export function calculateContainerCheckDigit(containerNumber: string): number {
  if (!containerNumber || containerNumber.length !== 10) {
    throw new Error('Invalid container number length')
  }

  const alphabet = '0123456789A BCDEFGHIJK LMNOPQRSTU VWXYZ'
  const sum = containerNumber.split('').reduce((acc, char, index) => {
    return acc + alphabet.indexOf(char) * 2 ** index
  }, 0)

  return (sum % 11) % 10
}
