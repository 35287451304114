<script lang="ts" setup>
import LFDDisplay from './LFDDisplay.vue'
import type { Container } from '~/models/containers'
import type { ImportStatus } from '~/models/importStatus'

const props = defineProps<{
  container: Container
  importStatus: ImportStatus
}>()
const last_free_day = computed(() => props.container.last_free_day || null)
const lfdDesc = computed(() => {
  if (props.importStatus.lfdInfo) {
    return props.importStatus.lfdInfo
  } else {
    return undefined
  }
})
</script>

<template>
  <LFDDisplay :last-free-date="last_free_day" :info="lfdDesc" />
</template>
