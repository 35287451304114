<script lang="ts" setup>
import { ElMessage, ElNotification } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import {
  TERMINALS_WITH_CHASSIS_OPTION_FOR_EMPTY_APPOINTMENT,
  getTerminalLabel,
} from '~/constants'
import type { EmptyInAppointmentSlot } from '~/models/appointmentSlot'
import { AppointmentBookingApi } from '~/services/apiClient'
import type {
  ContainerType,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { CONTAINER_RE, formatDT } from '~/utils'
import type { ReturnRule } from '~/models/returnRules'
import { useLicensePlateRules } from '~/compositions/useLicensePlateRules'
import LicensePlateSelector from '~/components/inputs/LicensePlateSelector.vue'

const props = defineProps<{
  modelValue: boolean
  terminal: TerminalName
  shippingLine: ShippingLine
  containerType: ContainerType
  bookingSlot: EmptyInAppointmentSlot
  returnRule: ReturnRule | null
  prefillContainerNumber?: string | null
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
const terminal = toRef(props, 'terminal')
const appointmentSlotWindowStart = toRef(props.bookingSlot, 'window_start')

const form = reactive({
  chassis_number: '',
  truck_license_plate: '',
  container_number: props.prefillContainerNumber || '',
})

const { usesLicensePlate, requiresLicensePlate, shouldPrefillLicensePlate } =
  useLicensePlateRules({
    terminal,
    appointmentWindowStart: appointmentSlotWindowStart,
    forEmptyBooking: true,
    existingAppointment: toRef(null),
  })

async function book() {
  const api = new AppointmentBookingApi()
  api
    .bookEmptyAppointmentAppointmentsBookingBookEmptyPost({
      slot: {
        terminal: props.terminal,
        window_start: props.bookingSlot.window_start.toISO(),
        group_observed: props.bookingSlot.group_observed_string,
      },
      container_number: form.container_number,
      container_type: props.containerType,
      shipping_line: props.shippingLine,
      chassis_number: form.chassis_number,
      truck_license_plate: form.truck_license_plate,
    })
    .then(() => {
      ElNotification({
        title: 'Appointment Booking begun',
        message: 'You will get a notification on success or failure',
        type: 'success',
      })
      emit('close')
    })
}
const formRef = ref<FormInstance>()
const submitForm = async () => {
  formRef
    .value!.validate()
    .then((isValid) => {
      if (isValid) {
        book()
      } else {
        ElMessage.error('Please fix form errors')
      }
    })
    .catch((err) => {
      ElMessage.error('Please fix form errors')
      console.error(err)
    })
}

const hasChassisNumberOption = computed(() =>
  TERMINALS_WITH_CHASSIS_OPTION_FOR_EMPTY_APPOINTMENT.has(props.terminal)
)
const formRules = reactive<FormRules<any>>({
  container_number: [
    {
      required: true,
      message: 'Required',
      trigger: 'change',
    },
    {
      trigger: 'change',
      pattern: CONTAINER_RE,
      message: 'Invalid container number',
    },
  ],
  chassis_number: [
    {
      required: false,
      message: 'Required',
      trigger: 'change',
    },
  ],
  truck_license_plate: [
    {
      required: requiresLicensePlate.value,
      message: 'Required',
      trigger: 'change',
    },
  ],
})
</script>

<template>
  <!-- TODO: Consolidate with BookingForm.vue? -->
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    title="Book Empty Appointment"
    cancel-button-text="Close"
    @close="emit('close')"
  >
    <el-form
      ref="formRef"
      :rules="formRules"
      :model="form"
      label-width="auto"
      status-icon
      :inline="false"
      class="mt-3 empty-booking-dialog-form"
    >
      <el-form-item label="Terminal:">
        {{ getTerminalLabel(terminal) }}
      </el-form-item>
      <el-form-item label="Shipping Line:">
        {{ shippingLine }}
      </el-form-item>
      <el-form-item label="Container Type:">
        {{ containerType }}
      </el-form-item>
      <el-form-item label="Time:">
        {{ formatDT(bookingSlot.window_start) }}
        <template v-if="bookingSlot.window_end">
          - {{ formatDT(bookingSlot.window_end) }}
        </template>
      </el-form-item>
      <el-form-item label="Container Number:" prop="container_number">
        <EmptyContainerSelector
          v-model="form.container_number"
          :limit-to-line-and-types="[
            { shipping_line: shippingLine, container_type: containerType },
          ]"
        />
      </el-form-item>
      <el-form-item
        v-if="usesLicensePlate"
        label="License plate #:"
        prop="truck_license_plate"
        data-testid="license-plate-form-item"
      >
        <LicensePlateSelector
          v-model="form.truck_license_plate"
          :license-plate="form.truck_license_plate"
          :terminal="terminal"
          :auto-select-value="shouldPrefillLicensePlate"
          class="w-full"
        />
      </el-form-item>
      <el-form-item
        v-if="hasChassisNumberOption"
        label="Chassis Number:"
        prop="chassis_number"
      >
        <el-input v-model="form.chassis_number" class="max-w-50" />
        <Help title="Chassis Input" class="text-gray-600 ml-1 mb-2px">
          Not setting a chassis number will automatically set the appointment to
          OWN chassis. Setting a chassis number will set the chassis type to
          pool
        </Help>
      </el-form-item>
    </el-form>
    <el-alert v-if="!props.returnRule" type="error" :closable="false">
      <p>
        <strong>Warning:</strong
        >{{ props.terminal.toUpperCase().replace('_', ' ') }} hasn't posted
        return rules for this time. You can still book it if you want.
      </p>
    </el-alert>
    <template #action-button>
      <el-button
        type="primary"
        size="large"
        :disabled="!form.container_number"
        @click="submitForm"
      >
        Book
      </el-button>
    </template>
  </BaseDialog>
</template>

<style lang="scss">
.empty-booking-dialog-form {
  margin-bottom: 8px;
  .el-form-item__label {
    font-weight: bold;
  }
  .el-form-item {
    margin-right: 15px;
  }
  .el-form-item--small {
    // height: 42px;
    margin-bottom: 0px;
  }
}
</style>
