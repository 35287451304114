import { acceptHMRUpdate, defineStore } from 'pinia'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import type {
  ActionType,
  BookingRequest,
  BookingVerificationNotification,
  Notification as SoketiNotification,
} from '~/services/apiClient'
import { ActionStatus, NotificationType } from '~/services/apiClient'

type BookingRequestCallback = (bookingRequest: BookingRequest) => void

export const useMutationRequestsStore = defineStore('mutation-requests', () => {
  const bookingRequestsByID = new Map<number, BookingRequest>() // TODO: Remove
  const bookingRequestStatesByRequestID = ref(
    new Map<number, BookingRequestState>()
  )
  const bookingRequestCallbacks = new Map<number, BookingRequestCallback[]>()

  function handleAppointmentVerificationEvent(
    notification: SoketiNotification
  ) {
    if (notification.event_type !== NotificationType.BookingVerification) {
      throw new Error(`Invalid event type ${notification.event_type}`)
    }
    const verificationInformation =
      notification.object as BookingVerificationNotification
    const bookingRequest = verificationInformation.action_request
    const bookingRequestState = bookingRequestStatesByRequestID.value.get(
      bookingRequest.id
    )
    if (!bookingRequestState) {
      console.warn(
        'No reschedule request found for booking request',
        bookingRequest.id
      )
      return
    }
    if (notification.level === 'success') {
      bookingRequestState.status = 'verified'
    } else {
      bookingRequestState.status = 'verification_issue'
    }
    bookingRequestState.appointmentID = bookingRequest.appointment_id
    bookingRequestState.latestMessage = `${notification.title}`
    if (notification.message) {
      bookingRequestState.latestMessage += `: ${notification.message}`
    }
    bookingRequestStatesByRequestID.value.set(
      bookingRequest.id,
      bookingRequestState
    )
  }
  function handleBookingRequestUpdatedEvent(notification: SoketiNotification) {
    if (notification.event_type !== NotificationType.BookingRequestUpdated) {
      throw new Error(`Invalid event type ${notification.event_type}`)
    }
    const bookingRequest = notification.object as BookingRequest
    bookingRequestsByID.set(bookingRequest.id, bookingRequest)
    const callbacks = bookingRequestCallbacks.get(bookingRequest.id)
    if (callbacks) {
      for (const callback of callbacks) {
        callback(bookingRequest)
      }
    }
    const bookingRequestState = bookingRequestStatesByRequestID.value.get(
      bookingRequest.id
    )
    if (!bookingRequestState) {
      console.warn(
        'No reschedule request found for booking request',
        bookingRequest.id
      )
      return
    }
    bookingRequestState.bookingRequest = bookingRequest
    switch (bookingRequest.status) {
      case ActionStatus.Completed:
        bookingRequestState.status = 'booked'
        break
      case ActionStatus.Failed:
        bookingRequestState.status = 'failed'
        break
      case ActionStatus.Rejected:
        bookingRequestState.status = 'rejected'
        break
      default:
        console.warn('Unhandled booking request status', bookingRequest.status)
    }
    // Sometimes we get the appointment immediately
    bookingRequestState.appointmentID = bookingRequest.appointment_id
    bookingRequestState.latestMessage = `${notification.title}`
    if (notification.message) {
      bookingRequestState.latestMessage += `: ${notification.message}`
    }
  }
  function handleNewBookingRequest(state: BookingRequestState) {
    bookingRequestStatesByRequestID.value.set(state.requestID, state)
  }
  function registerBookingCallback(
    requestID: number,
    callback: BookingRequestCallback
  ) {
    let callbacks = bookingRequestCallbacks.get(requestID)
    if (!callbacks) {
      callbacks = []
      bookingRequestCallbacks.set(requestID, callbacks)
    }
    callbacks.push(callback)
  }
  return {
    bookingRequestsByID,
    bookingRequestStatesByRequestID,
    handleNewBookingRequest,
    handleBookingRequestUpdatedEvent,
    handleAppointmentVerificationEvent,
    registerBookingCallback, // TODO: Remove
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMutationRequestsStore, import.meta.hot)
  )
}

export type BookingRequestStatus =
  | 'pending'
  | 'retrying'
  | 'rejected'
  | 'failed'
  | 'booked'
  | 'verified'
  | 'verification_issue'

export interface BookingRequestState {
  requestID: number
  actionType: ActionType
  selectedSlot: BaseAppointmentSlot
  originalAppointmentID: number | undefined // For reschedules
  status: BookingRequestStatus
  bookingRequest: BookingRequest | undefined
  appointmentID: number | undefined
  latestMessage: string | undefined
}
