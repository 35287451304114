import { DateTime } from 'luxon'
import { TERMINAL_NOT_REQUIRING_APPOINTMENTS } from '~/constants'
import {
  AppointmentSlot,
  BaseAppointmentSlot,
  EmptyInAppointmentSlot,
} from '~/models/appointmentSlot'
import { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { Appointment } from '~/models/appointments'
import BookingRequest from '~/models/bookingRequest'
import type { CapacitySet } from '~/models/capacitySets'
import { makeCapacitySetFromAPI } from '~/models/capacitySets'
import { Container } from '~/models/containers'
import { TerminalGateSchedule } from '~/models/gateSchedules'
import { ReturnRule } from '~/models/returnRules'
import { UserInfo } from '~/models/userInfo'
import {
  ActionStatus,
  ActionType,
  AppointmentStatus,
  ContainerType,
  ContainerWatchState,
  DayPreferenceType,
  GateStatus,
  ImportLocation,
  OldBookingStatus,
  OrganizationSubscription,
  PortalName,
  PreferenceOption,
  ReceivingRule,
  Shift,
  ShippingLine,
  TerminalName,
  TransactionDirection,
} from '~/services/apiClient'
import type {
  AutoBookRequestPreset,
  CapacitySetRemainingCapacities,
  GETAppointment,
  GETAppointmentWithContainer,
  GETBaseAppointmentSlot,
  BookingRequest as GETBookingRequest,
  GETBookingRequestOldWithApptNum,
  GETCapacityBucket,
  GETCapacitySet,
  GETContainer,
  GETContainerAvailability,
  GETContainerBasic,
  GETCurrentTerminalGateScheduleResponse,
  GETEmptyInAppointmentSlot,
  GETEmptyReturnRule,
  GETGateScheduleEntryResponse,
  GETImportStatus,
  GETOrganization,
  GETPierPass,
  GETTruck,
  GETTruckWithStats,
  GETUserInfo,
  GETVesselETAs,
  GETWatch,
  BaseGateTransaction as GateTransaction,
  SimpleUser,
  TerminalReadingRules,
} from '~/services/apiClient'
import { calculateContainerCheckDigit } from '~/utils'

export function generateRandomContainerNumber(): string {
  const prefixes = ['CSNU', 'TGBU', 'MEDU', 'FCIU', 'YMMU', 'MAEU', 'EGLV']
  const prefix = prefixes[Math.floor(Math.random() * prefixes.length)]
  const numbers = Math.floor(Math.random() * 900000) + 100000 // 6-digit number
  const baseNumber = `${prefix}${numbers}`
  const checkDigit = calculateContainerCheckDigit(baseNumber)
  return `${baseNumber}${checkDigit}`
}

export function getRandomTerminal(): TerminalName {
  const terminals = Object.values(TerminalName).filter(
    (terminal) => !TERMINAL_NOT_REQUIRING_APPOINTMENTS.has(terminal)
  )
  return terminals[Math.floor(Math.random() * terminals.length)]
}

export function createGETImportStatus(
  status?: Partial<GETImportStatus>
): GETImportStatus {
  return {
    id: 2,
    cycle_id: 2,
    terminal: TerminalName.Apm,
    source: PortalName.ApmTermpointApi,
    location: 'in terminal',
    parsed_location: ImportLocation.InTerminal,
    container_number: 'CBHU8733881',
    last_free_date: '2021-09-10T00:00:00.000Z',
    holds: [],
    ready_for_appointment: true,
    raw_data: {},
    extra_data: {},
    observed: '2021-09-27T17:32:39.232453+00:00',
    last_initiated_check: '2021-09-27T23:50:15.320766+00:00',
    last_observed: '2021-09-27T23:50:15.320766+00:00',
    parsed_holds: [],
    ...status,
  }
}

export function createGETAppointment(
  appointment?: Partial<GETAppointment>
): GETAppointment {
  return {
    id: 1,
    container_number: 'CBHU8733881',
    direction: TransactionDirection.Outbound,
    loaded: true,
    status: AppointmentStatus.Scheduled,
    window_start: '2021-09-10T00:00:00.000Z',
    window_end: '2021-09-10T01:00:00.000Z',
    linked_appointments: [],
    terminal: TerminalName.Apm,
    terminal_reference: 'APM123',
    observed: '2021-09-27T17:32:39.232453+00:00',
    source: PortalName.ApmTermpointApi,
    extra_data: {},
    ...appointment,
  }
}
export function createAppointment(
  appointment?: Partial<GETAppointment>
): Appointment {
  return new Appointment(createGETAppointment(appointment), false)
}

export function createGETAppointmentWithContainer(
  appointmentInfo?: Partial<GETAppointmentWithContainer>
): GETAppointmentWithContainer {
  return {
    appointment: createGETAppointment(),
    watch: createGETWatch(),
    ...appointmentInfo,
  }
}

export function createRandomGETAppointmentsWithContainers({
  numAppointments,
  after,
  before,
  direction,
  loaded,
  statuses,
}: {
  numAppointments: number
  after?: DateTime
  before?: DateTime
  direction?: TransactionDirection
  loaded?: boolean
  statuses?: AppointmentStatus[]
}): GETAppointmentWithContainer[] {
  const now = DateTime.now()
  let appointmentID = 1
  const rangeStart = after ?? now
  const rangeEnd = before ?? now.plus({ days: 5 })
  const rangeHours = rangeEnd.diff(rangeStart, 'hours').hours
  function makeAppointment({
    directionOverride,
    windowStartOverride,
  }: {
    directionOverride?: TransactionDirection
    windowStartOverride?: DateTime
  }) {
    const containerNumber = generateRandomContainerNumber()
    const window_start =
      windowStartOverride ??
      rangeStart
        .plus({ hours: Math.floor(Math.random() * rangeHours) })
        .startOf('hour')
    let window_end: string | undefined
    if (Math.random() < 0.5) {
      window_end = window_start.plus({ hours: 1 }).toISO()
    }
    let directionForAppointment = directionOverride ?? direction
    if (!directionForAppointment) {
      directionForAppointment =
        Math.random() < 0.5
          ? TransactionDirection.Outbound
          : TransactionDirection.Inbound
    }
    const chanceLoaded =
      directionForAppointment === TransactionDirection.Outbound ? 0.8 : 0.2
    // Range of -4 to 4 days to LFD
    const daysToLFD = Math.floor(Math.random() * 9) - 4
    const lfd = window_start.plus({ days: daysToLFD })
    return createGETAppointmentWithContainer({
      appointment: createGETAppointment({
        id: appointmentID++,
        terminal_reference: `APPT-${appointmentID}`,
        container_number: containerNumber,
        window_start: window_start.toISO(),
        window_end,
        terminal: getRandomTerminal(),
        direction: directionForAppointment,
        loaded: loaded ?? Math.random() < chanceLoaded,
        status:
          statuses?.[Math.floor(Math.random() * statuses.length)] ??
          AppointmentStatus.Scheduled,
      }),
      watch: createGETWatch({
        container_number: containerNumber,
        import_last_free_date: lfd.toISO(),
      }),
    })
  }
  const appointments = Array.from({
    length: numAppointments,
  }).map(() => {
    return makeAppointment({})
  })
  // Add some dual appointments
  const chanceDualAppointment = 0.5
  const dualAppointments = appointments
    .map((appointment) => {
      if (Math.random() < chanceDualAppointment) {
        // Form a dual appointment
        const directionOverride =
          appointment.appointment.direction === TransactionDirection.Outbound
            ? TransactionDirection.Inbound
            : TransactionDirection.Outbound
        const dualAppointment = makeAppointment({
          directionOverride,
          windowStartOverride: DateTime.fromISO(
            appointment.appointment.window_start
          ),
        })
        dualAppointment.appointment.linked_appointments = [
          {
            terminal_reference: appointment.appointment.terminal_reference,
            container_number: appointment.appointment.container_number,
          },
        ]
        return dualAppointment
      }
      return undefined
    })
    .filter((appt) => appt !== undefined)
  return appointments.concat(dualAppointments)
}

export function createAppointmentWithContainerInfo(
  appointmentInfo?: Partial<GETAppointmentWithContainer>
): AppointmentWithContainerInfo {
  return new AppointmentWithContainerInfo(
    createGETAppointmentWithContainer(appointmentInfo),
    false
  )
}

export function createAppointmentWithContainerInfo_simple({
  containerNumber,
  containerType = ContainerType._40Hc,
  shippingLine = ShippingLine.Maeu,
  windowStart,
  direction = TransactionDirection.Outbound,
  loaded = true,
  status = AppointmentStatus.Scheduled,
}: {
  containerNumber: string
  containerType?: ContainerType
  shippingLine?: ShippingLine
  windowStart: DateTime
  direction?: TransactionDirection
  loaded?: boolean
  status?: AppointmentStatus
}) {
  return createAppointmentWithContainerInfo({
    appointment: createGETAppointment({
      container_number: containerNumber,
      window_start: windowStart.toISO(),
      window_end: undefined,
      direction,
      loaded,
      status,
    }),
    watch: createGETWatch({
      container_number: containerNumber,
      container_type: containerType,
      shipping_line: shippingLine,
    }),
  })
}

export function createGETWatch(watch?: Partial<GETWatch>): GETWatch {
  return {
    container_number: 'CBHU8733881',
    last_related_terminal: TerminalName.Apm,
    state: ContainerWatchState.ImportApptBooking,
    assigned_users: [],
    tags: [],
    ...watch,
  }
}

export function createGETPierPass(
  pierPass: Partial<GETPierPass> = {}
): GETPierPass {
  return {
    terminals: [TerminalName.Apm],
    container_number: 'CBHU8733881',
    claimed_by_other_company: false,
    fee_paid: true,
    has_hold: false,
    observed: '2021-09-27T17:32:39.232453+00:00',
    ...pierPass,
  }
}

export function createGETContainer(
  container?: Partial<GETContainer>
): GETContainer {
  const importStatus = createGETImportStatus(
    container?.import_status ?? {
      shipping_line: 'MAE',
      parsed_shipping_line: ShippingLine.Maeu,
      parsed_container_type: ContainerType._40Hc,
    }
  )
  return {
    container_number: 'CBHU8733881',
    import_statuses: [importStatus],
    assigned_users: [],
    auto_book_on: false,
    cycle_state: ContainerWatchState.ImportApptBooking,
    other_appointment_counts: [],
    import_status: importStatus,
    last_related_terminal: TerminalName.Apm,
    shipping_line: ShippingLine.Maeu,
    container_type: ContainerType._40Hc,
    watched_time: DateTime.now().toISO(),
    tags: [],
    pier_pass: createGETPierPass(),
    clean_truck_fee: createGETPierPass(),
    ...container,
  }
}

export function createContainer(
  container?: Partial<GETContainer>,
  demoMode = false
): Container {
  return new Container(createGETContainer(container), demoMode)
}

export function createGETGateTransaction(
  transaction?: Partial<GateTransaction>
): GateTransaction {
  return {
    terminal: TerminalName.Apm,
    container_number: 'CBHU8733881',
    time: '2023-10-01T00:00:00.000Z',
    loaded: false,
    direction: TransactionDirection.Inbound,
    raw_data: {},
    observed: '2023-10-01T00:00:00.000Z',
    ...transaction,
  }
}

export function createSimpleUser(user?: Partial<SimpleUser>): SimpleUser {
  return {
    id: 1,
    first_name: 'John',
    last_name: 'Doe',
    ...user,
  }
}

export function createGETOrganization(
  org?: Partial<GETOrganization>
): GETOrganization {
  return {
    id: 1,
    name: 'Test Org',
    scac: 'TEST',
    config: {},
    subscriptions: [OrganizationSubscription.DrayDogMain],
    ...org,
  }
}

export function createGETUserInfo(
  userInfo?: Partial<GETUserInfo>
): GETUserInfo {
  const org = createGETOrganization(userInfo?.current_organization)
  const orgs = userInfo?.organizations ?? [org]
  return {
    id: 529,
    first_name: 'Nathaniel',
    last_name: 'Brown',
    email: 'nathaniel@draydog.com',
    preferences: {},
    current_organization: org,
    organizations: orgs,
    feature_flags: [],
    ...userInfo,
  }
}

export function createUserInfo(
  userInfo?: Partial<GETUserInfo>,
  demoMode = false
): UserInfo {
  return new UserInfo(createGETUserInfo(userInfo), demoMode)
}

export function createGETEmptyReturnRule(
  emptyReturnRule?: Partial<GETEmptyReturnRule>
): GETEmptyReturnRule {
  return {
    terminal: TerminalName.Apm,
    shipping_line: 'Maersk',
    parsed_shipping_line: ShippingLine.Maeu,
    container_type: '40HC',
    parsed_container_type: ContainerType._40Hc,
    rule: ReceivingRule.Open,
    group_last_observed: '2021-09-27T17:32:39.232453+00:00',
    group_first_observed: '2021-09-27T17:32:39.232453+00:00',
    reading_last_parsed: '2021-09-27T17:32:39.232453+00:00',
    screenshot_url: 'https://example.com/screenshot.png',
    ...emptyReturnRule,
  }
}

export function createEmptyReturnRule(
  emptyReturnRule?: Partial<GETEmptyReturnRule>
): ReturnRule {
  return new ReturnRule(createGETEmptyReturnRule(emptyReturnRule))
}

export function createGETGateSchedule(
  schedule?: Partial<GETCurrentTerminalGateScheduleResponse>
): GETCurrentTerminalGateScheduleResponse {
  return {
    terminal: TerminalName.Apm,
    entries: [
      // APM
      createGETGateScheduleEntryResponse({
        date: '2024-03-12',
        shift: Shift.First,
        status: GateStatus.Open,
        notes: ['Automation only'],
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-13',
        shift: Shift.Second,
        status: GateStatus.Closed,
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-13',
        shift: Shift.Third,
        status: GateStatus.Closed,
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-14',
        shift: Shift.First,
        status: GateStatus.Open,
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-14',
        shift: Shift.First,
        status: GateStatus.Open,
        notes: ['Open for everyone'],
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-14',
        shift: Shift.Second,
        status: GateStatus.Closed,
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-16',
        shift: Shift.First,
        status: GateStatus.Closed,
        start_time: '2024-03-16T02:00:00.000Z', // After the end of the shift
        end_time: '2024-03-16T01:00:00.000Z',
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-16',
        shift: Shift.Second,
        status: GateStatus.Open,
      }),
      createGETGateScheduleEntryResponse({
        date: '2024-03-17',
        shift: Shift.First,
        status: GateStatus.Closed,
      }),
    ],
    screenshot_url: 'https://draydog.com/screenshot.png',
    ...schedule,
  }
}

export function createGETGateScheduleEntryResponse(
  entry?: Partial<GETGateScheduleEntryResponse>
): GETGateScheduleEntryResponse {
  return {
    date: '2024-03-10',
    shift: Shift.First,
    status: GateStatus.Open,
    notes: [],
    start_time: '2024-03-10T00:00:00.000Z',
    end_time: '2024-03-10T01:00:00.000Z',
    raw_period_description: '12:00 AM - 1:00 AM',
    ...entry,
  }
}

export function createTerminalGateSchedule(
  schedule?: Partial<GETCurrentTerminalGateScheduleResponse>
): TerminalGateSchedule {
  return new TerminalGateSchedule(createGETGateSchedule(schedule))
}

export function createGETContainersBasic(
  container?: Partial<GETContainerBasic>
): GETContainerBasic {
  return {
    container_number: 'CBHU8733881',
    last_related_terminal: TerminalName.Apm,
    cycle_state: ContainerWatchState.ImportApptBooking,
    tags: [],
    ...container,
  }
}

export function createGETCapacitySet(
  capacitySet?: Partial<GETCapacitySet>
): GETCapacitySet {
  const buckets: GETCapacityBucket[] = []
  for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
    // Create a bucket for each day at 0 hours (midnight) with 0 max_appointments
    buckets.push(
      createGETCapacityBucket({
        day_of_week: dayOfWeek,
        hour_of_day: 0,
        max_appointments: 0,
      })
    )
  }

  return {
    buckets,
    timezone: 'America/Los Angeles',
    ...capacitySet,
  }
}

export function createCapacitySet(
  capacitySet?: Partial<GETCapacitySet>
): CapacitySet {
  return makeCapacitySetFromAPI(createGETCapacitySet(capacitySet), false)
}

export function createGETCapacityBucket(
  bucket?: Partial<GETCapacityBucket>
): GETCapacityBucket {
  return {
    day_of_week: 0,
    hour_of_day: 0,
    max_appointments: 0,
    ...bucket,
  }
}

export function createEmptyInAppointmentSlot(
  slot?: Partial<GETEmptyInAppointmentSlot>,
  group_observed: string = '2024-03-10T00:00:00.000Z'
): EmptyInAppointmentSlot {
  return new EmptyInAppointmentSlot(
    {
      window_start: '2024-03-10T00:00:00.000Z',
      window_end: undefined,
      num_appointments_available: 3,
      ...slot,
    },
    group_observed
  )
}

export function createGETBaseAppointmentSlot(
  slot?: Partial<GETBaseAppointmentSlot>
): GETBaseAppointmentSlot {
  return {
    window_start: '2024-03-10T00:00:00.000Z',
    window_end: undefined,
    num_appointments_available: 2,
    ...slot,
  }
}

export function createBaseAppointmentSlot(
  slot?: Partial<GETBaseAppointmentSlot>
): BaseAppointmentSlot {
  return new BaseAppointmentSlot(createGETBaseAppointmentSlot(slot))
}

export function createGETContainerAvailability(
  availability?: Partial<GETContainerAvailability>
): GETContainerAvailability {
  return {
    container_number: 'CBHU8733881',
    terminal: TerminalName.Apm,
    has_availability: true,
    slots: [],
    observed: '2024-03-10T00:00:00.000Z',
    ...availability,
  }
}
export function createAppointmentSlot(
  availability?: Partial<GETContainerAvailability>,
  slot?: Partial<GETBaseAppointmentSlot>
): AppointmentSlot {
  const parsedAvailability = createGETContainerAvailability(availability)
  const parsedSlot = createGETBaseAppointmentSlot(slot)
  return new AppointmentSlot(parsedAvailability, parsedSlot)
}

export function createGETTerminalReadingRules(
  terminalRulesObj?: Partial<TerminalReadingRules>
): TerminalReadingRules {
  return {
    terminal: TerminalName.Apm,
    reading_first_observed: '2021-01-01T00:00:00.000Z',
    reading_last_observed: '2021-01-01T00:00:00.000Z',
    reading_last_parsed: '2021-01-01T00:00:00.000Z',
    rules: [],
    cache_valid: true,
    ...terminalRulesObj,
  }
}

export function createGETVesselETAs(
  etas?: Partial<GETVesselETAs>
): GETVesselETAs {
  return {
    vessel_id: 1,
    name: 'Test Vessel 1',
    imo_number: 1,
    eta: {
      eta: '2022-01-01T00:00:00Z',
      first_observed: '2022-01-01T00:00:00Z',
      portal_name: PortalName.VesselFinder,
    },
    other_etas: [],
    ...etas,
  }
}

export function createCapacitySetRemainingCapacities(
  capacitySet?: Partial<CapacitySetRemainingCapacities>
): CapacitySetRemainingCapacities {
  return {
    capacity_set: {
      container_tag: undefined,
      independent_capacity: false,
    },
    remaining_capacity_periods: [
      {
        capacity: 10,
        num_booked: 0,
        start: '2023-01-01T00:00:00Z',
        end: '2023-01-01T01:00:00Z',
      },
    ],
    ...capacitySet,
  }
}

export function createGETTruck(truck?: Partial<GETTruck>): GETTruck {
  return {
    id: 1,
    license_plate_number: 'ABCD123',
    name: "Jimmy's Truck",
    ...truck,
  }
}

export function createGETTruckWithStats(
  truckWithStats?: Partial<GETTruckWithStats>
): GETTruckWithStats {
  return {
    truck: createGETTruck(),
    num_completed: 0,
    num_scheduled: 0,
    num_missed: 0,
    ...truckWithStats,
  }
}

export function createGETBookingRequestOld(
  bookingRequest?: Partial<GETBookingRequestOldWithApptNum>
): GETBookingRequestOldWithApptNum {
  return {
    window_start: '2023-01-01T00:00:00Z',
    entered_in_tms_time: '2023-01-01T01:00:00Z',
    auto_book_request_id: undefined,
    reschedule_cancellation_request_id: undefined,
    reschedule_request_id: undefined,
    status: OldBookingStatus.Booked,
    id: 1,
    terminal: TerminalName.Apm,
    container_number: 'ABCD1234567',
    requested_by: createSimpleUser(),
    created: '2023-01-01T00:00:00Z',
    action_type: ActionType.Book,
    appointment_terminal_reference: undefined,
    ...bookingRequest,
  }
}
export function createGETBookingRequest(
  request?: Partial<GETBookingRequest>
): GETBookingRequest {
  return {
    id: 1,
    terminal: TerminalName.Apm,
    container_number: 'MAEU1234567',
    status: ActionStatus.Completed,
    created: DateTime.now().toISO(),
    organization_id: 1,
    truck_license_plate: '',
    connection_id: 1, // No way to get this sadly
    portal_name: PortalName.Apm,
    window_start: DateTime.now().plus({ days: 1 }).endOf('hour').toISO(),
    master_bill_of_lading: '',
    updated: DateTime.now().toISO(),
    ...request,
  }
}

export function createBookingRequest(
  bookingRequest?: Partial<GETBookingRequestOldWithApptNum>
): BookingRequest {
  return new BookingRequest(createGETBookingRequestOld(bookingRequest), false)
}

export function createAutoBookRequestPreset(
  preset?: Partial<AutoBookRequestPreset>
): AutoBookRequestPreset {
  return {
    name: 'Test Preset',
    settings: {
      day_preference: DayPreferenceType.Earliest,
      min_advance_notice_hours: 1,
      timezone: 'America/Los_Angeles',
      time_preferences: [
        {
          start_local: '08:00',
          preference: PreferenceOption.High,
        },
      ],
    },
    ...preset,
  }
}
